/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { createGlobalStyle, ThemeProvider } from "styled-components"
import { tiberioBlack, tiberioLight } from "../../themes"

import { FormattedMessage, IntlProvider } from "react-intl"
import "@formatjs/intl-pluralrules/polyfill"

import { getCurrentLangKey } from "ptz-i18n"

export const GlobalStyle = createGlobalStyle`
  *{
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }
  body {
    margin: 0;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${props => props.theme.backgroundColor};
    overflow-x: hidden;
    color: ${props => props.theme.textColor};
    line-height: normal;
  }

  a {
    color: ${props => props.theme.textColor};
  }

  section {
    position: relative;
    height: 100vh;
    width: 100vw;
  }

  p {
    line-height: normal;
  }
`

const Layout = ({ children, location, messages }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  //this is changed tp get langs
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `)

  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, location.pathname)

  return (
    <>
      <ThemeProvider theme={tiberioBlack}>
        <IntlProvider locale={langKey} messages={messages}>
          <GlobalStyle />
          {children}
        </IntlProvider>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any.isRequired,
  messages: PropTypes.any.isRequired,
}

export default Layout
