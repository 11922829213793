export const tiberioBlack = {
    accentColor: '#ff9c00',
    textColor: '#ffffff',
    backgroundColor : '#000000'
};

export const tiberioLight = {
    accentColor: '#ff9c00',
    textColor: '#1d1716',
    backgroundColor : '#efefe4'
};
